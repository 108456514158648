import React, { useEffect, useState, useRef } from 'react';
import { useHistory } from 'react-router-dom';
import 
{ IonPage
, IonContent
, IonButton
, IonInput
, IonText
} from '@ionic/react';

//Utils
import logger from '../../../utils/logger';
import { useToast } from '@agney/ir-toast';
import { setAccessToken } from '../../../utils/session';
import 
{ acceptAgreements
, loginVerificationCodeStart
, loginVerificationCodeCheck
, logSigninEvent
} from '../../../utils/services';
import { formatPhoneNumber } from '../../../utils/validateData'

//context
import { useLogin } from '../../../context/LoginContext';
import { useSubdomain } from '../../../context/SubdomainContext';
import { useSession } from "../../../context/SessionContext";

//styles
import styles from './VerificationCode.module.css';
import Header from '../../layout/Header/Header';

const maxChallengeCodeLen = 5;

function VerificationCode(props) {
  const history = useHistory();
  const Toast = useToast();
  const verificationCodeInputRef = useRef(null);
  const { loginValue } = useLogin();
  const [ retries, setRetries ] = useState(0);
  const { setSession } = useSession();
  const { subdomain } = useSubdomain();
  const [ showHeaders, setShowHeaders ] = useState(true);
  const [ signinRequest, setSigninRequest ] = useState();

  useEffect(() => {
    setSigninRequest(props?.history?.location?.state?.signinRequest);
  }, [props.history.location.state]);

  const handleVerifyCode = async () => {
    const challengeCode = verificationCodeInputRef?.current?.value;
    if (challengeCode.length < maxChallengeCodeLen) {
      await logSigninEvent(signinRequest, 11.01);
      Toast.create({message: `Verification code must be ${maxChallengeCodeLen} digits. Please correct and try again.`, color: 'warning', duration: 3000, position: 'top'}).present();
      return;
    }
    if (isNaN(Number(challengeCode))) {
      await logSigninEvent(signinRequest, 11.02);
      Toast.create({message: `Verification code must numeric. Please correct and try again.`, color: 'warning', duration: 3000, position: 'top'}).present();
      return;
    }

    let msgBody = {challengeCode};
    if (subdomain) msgBody['subdomain'] = subdomain;

    // Verify the challenge code
    const challengeResp = await loginVerificationCodeCheck(loginValue, msgBody);

    // status 201 is unsuccessful code verification
    if (challengeResp?.status !== 201) {
      logger.warn(`VerificationCode: handleVerifyCode() failed - status: ${challengeResp?.status}, message: ${challengeResp?.message}`, loginValue);

      // status 429 twilio has exceeded the max attmepts for this phone number, the user must wait 10 minutes
      if (challengeResp?.status === 429) {
        await logSigninEvent(signinRequest, 11.03);
        Toast.create({color: 'warning', message: 'Maximum attempts for this phone exceeded. You must wait 10 minutes before trying again.', duration: 5000, position: 'top'}).present();
        setTimeout(() => { history.replace('/'); }, 1000);
        return;
      }
      
      if (retries < 3) {
        setRetries(retries+1);
        await logSigninEvent(signinRequest, 11.04, {retries});
        Toast.create({color: 'warning', message: 'Incorrect code, please verify and try again.', duration: 2000, position: 'top'}).present();
        return;
      }

      // on the 3rd failed attempt, re-submit a request for a verification code. Twilio will send a new verify text with the same code.

      setRetries(0);
      const newCodeResp = await loginVerificationCodeStart(loginValue); // Have twilio resend the code. Twilio will not change the code within a 10 minute window.
      if (newCodeResp.status===200) {
        Toast.create({color: 'warning', message: 'Incorrect code, we will resend your code.', duration: 5000, position: 'top'}).present();
        return;
      }
      // corner case if submitting requests from 2 devices. Max attempts reached.
      if (newCodeResp.status===429) {
        await logSigninEvent(signinRequest, 11.05);
        Toast.create(
          { color: 'warning'
          , message: newCodeResp?.message
          , position: 'top'
          , buttons: [{side: 'end', icon: 'close', text: 'close', role: 'cancel' }]
          }
        ).present();
        setTimeout(() => { history.replace('/'); }, 1000);
        return;
      }

      // unknown twilio error condition
      await logSigninEvent(signinRequest, 11.06);
      Toast.create({ message: `System error - ${newCodeResp?.message}`, color: 'danger', position: 'top', buttons: [{side: 'end', icon: 'close', text: 'close', role: 'cancel' }]}).present();
      return;
    }

    await logSigninEvent(signinRequest, 11.07);
    // If we are in Account Recovery mode, this access token will have limited access to routes
    setAccessToken(challengeResp.data.token, false);
    setSession(true);       // start the timer for this session timeout alert
    acceptAgreements();

    if (challengeResp.data?.claimsDeviceCheckAdded) {
      logger.event('device-check');
    }

    history.replace({pathname:'/add-fido', state: { signinRequest }, search: history.location.search});
  };

  return (
    <IonPage>
      <Header page='Verification Code' />
      <IonContent className="ion-padding">
        <div className={styles.VerificationCode}>
          <IonText className={styles.ContainerText}>
            { showHeaders &&
              <h1>Device Verification</h1>
            }
            <h2 data-testid='verification-code-text'>
              Please enter the verification code sent to:
            </h2>
            <h2 className={styles.Number}>{formatPhoneNumber(loginValue)}</h2>
          </IonText>
          <IonInput
            ref={verificationCodeInputRef}
            className={styles.IonInput}
            data-testid='verification-code-input'
            inputmode='numeric'
            maxlength={maxChallengeCodeLen}
            minlength={maxChallengeCodeLen}
            debounce={0}
            onFocus={()=>setShowHeaders(false)}
            required='true'
            size='12'
            type='number'
            autocomplete='one-time-code'
          />
          <IonButton
            type='submit'
            className={styles.IonButton}
            onClick={handleVerifyCode}
            size='default'
            data-testid='verification-code-button'
          >
            Verify Code
          </IonButton>
        </div>
      </IonContent>

    </IonPage>
  );
}

export default VerificationCode;
