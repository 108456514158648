import React, { useEffect, useState } from 'react';
import
{ IonPage
, IonContent
, IonImg
, IonItemDivider
, IonText
, IonButton
} from '@ionic/react';

//Context
import { useVerifyCounter } from '../../../context/VerifyCounterContext';
import { useSubdomain } from '../../../context/SubdomainContext';

//Styles
import styles from './Dashboard.module.css';

//Utils
import 
{ getVerificationRequest
, getRelyingPartyInfo
, logSigninEvent
, verifyResponse
} from '../../../utils/services';
import { useToast } from '@agney/ir-toast';
import logger from '../../../utils/logger';

//Images
import logo from '../../../assets/logos/idgo_lockup.png';
import Header from "../../layout/Header/Header";

function Dashboard(props) {
  const Toast = useToast();
  const [ loadingApprovePrompt, setLoadingApprovePrompt ] = useState(true);
  const [ showAuthConfirmPrompt, setShowAuthConfirmPrompt ] = useState(false);
  const [ authConfMsg, setAuthConfMsg ] = useState({messageHeader:'',messageBody:'',verificationRequestId:null});
  const [ subdomainName, setSubdomainName ] = useState();
  const { subdomain } = useSubdomain();
  const { verifyCounter } = useVerifyCounter();
  const [ signinRequest, setSigninRequest ] = useState();
  const [ buttonsDisabled, setButtonsDisabled ] = useState(true);

  useEffect(() => {
    if (!props?.history?.location?.state?.signinRequest) return;
    setSigninRequest(props.history.location.state.signinRequest);
  }, [props?.history?.location?.state]);

  useEffect(() => {
    if (!subdomain) return;
    async function __getRPName() {
      const response = await getRelyingPartyInfo(subdomain);
      if (response.status !== 200) return;
      setSubdomainName(response.data.name);
    }
    __getRPName();
  }, [subdomain]);

  useEffect(() => {
    if (!subdomain) return;
    if (!signinRequest) return;

    if (signinRequest.signinType==='e') {
      logger.event('enrollment-complete'); // post message to IDgo Agent
      return;
    }

    async function __fetchAuthConfirmationPrompt () {
      if (!verifyCounter) return;
      
      const response = await getVerificationRequest(subdomain, verifyCounter);

      if (response?.status !== 200) {
        await logSigninEvent(signinRequest, 50.02);
        Toast.create({ message: `Error retrieving auth request. HTTP status:${response?.status}, message:${response?.message}`, color: 'warning', duration: 3000, position: 'top' }).present();
        return;
      }

      const vr = response?.data?.verificationRequest;
      if (vr?.status) {
        // Showing users the fact that a authorization request has already been completed has created some confusion, so we are going to hide it for now...
        //Toast.create({ message: `This authentication request has been completed with "${vr.status}"`, color: 'success', duration: 3000, position: 'top' }).present();
        return;
      }
      
      await logSigninEvent(signinRequest, 50.03);

      setAuthConfMsg({
        messageHeader: vr?.messageHeader,
        messageBody: vr?.messageBody,
        verificationRequestId: vr?.verificationRequestId
      })
      setShowAuthConfirmPrompt(true);
      setLoadingApprovePrompt(false);
      setButtonsDisabled(false);
    }

    if (signinRequest.signinType==='a') {
      __fetchAuthConfirmationPrompt();
      return;
    }
  }, [Toast, subdomain, verifyCounter, signinRequest]);

  const handleUserResponse = async (userResponse) => {
    await verifyResponse(subdomain, authConfMsg.verificationRequestId, userResponse)
    setShowAuthConfirmPrompt(false);
  };

  const renderDashboard = () => {
    if (!signinRequest?.signinType) {
      return <div className={styles.loadingPage}></div>;
    }

    if (signinRequest.signinType==='a') {
      if (loadingApprovePrompt) {
        return <div className={styles.loadingApprove}></div>;
      }
      if (showAuthConfirmPrompt) {
        return renderChoise();
      }
      return <>
        <p className={styles.HeaderSubMessage}>
          Thank you for using <b>IDgo</b> to securely authenticate yourself.
        </p>
        <div className={styles.CloseThisWindow}>
          (This window or tab can now be closed)
        </div>
      </>
    }

    // fall-through for signinTypes 'e' and 'c'
    return <>
      <h1 className={styles.HeaderMessage}>You're all set!</h1>
      <IonItemDivider className={styles.IonItemDivider} id={styles.TextDivider} />
      <p className={styles.HeaderSubMessage}>
        Now you can use <b>IDgo</b> to securely authenticate online, in the contact center or in person.
      </p>
    </>
};

const renderChoise = () => {
  return <>
    <IonText className={styles.ContainerText}>
      <h2 className={styles.HeaderMessage}>
        {authConfMsg.messageHeader}
      </h2>
      <h2 className={styles.HeaderSubMessage}>
        {authConfMsg.messageBody}
      </h2>
    </IonText>
    <div className={styles.ButtonBox}>
      <IonButton
        className={styles.ButtonNo}
        onClick={() => handleUserResponse('rejected')}
        disabled={buttonsDisabled}
        shape='round'
        color='danger'
        size='large'
        >No
      </IonButton>
      <div className={styles.ButtonSpacer}></div>
      <IonButton
        className={styles.ButtonYes}
        onClick={() => handleUserResponse('pass')}
        disabled={buttonsDisabled}
        shape='round'
        color='success'
        size='large'
        >Yes
      </IonButton>
    </div>
  </>
};

  return (
    <IonPage>
      <Header page={subdomainName} menuButton={true} /> 
      <IonContent className='ion-padding' id={styles.ContentDashboard}>
        <div className={styles.DashboardPage}>

          <IonText>
            { renderDashboard() }
          </IonText>
  
          <div className={styles.ContainerImg}>
            <IonImg src={logo} data-testid='id-go-logo' alt='id-go-logo' />
          </div>

        </div>
      </IonContent>
    </IonPage>
  );
}

export default Dashboard;